import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'
import { truncateString } from '../utils'

export default function Validations({ 
  appProps, itemsPath, exportPath, initialValidations, initialTotalPages, 
  selectedColumns, showTabs, displayedTabs, initialTotalCount
}) {
  const [validations, setValidations] = useState(initialValidations)
  const [relevantValidationsCount, setRelevantValidationsCount] = useState(initialTotalCount)

  const actions = []
  const tabs = displayedTabs

  function renderValidation(item, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        return (
          <div key={ column.key } className='item flex-1'>
            <div>
              { column.label == 'validator' ? (item[key] ? truncateString(item[key], 20) : '-') : (item[key] || '-') }
            </div>
          </div>
        )
      })}
    </>
    )
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.validations }
        breadcrumbs={ appProps.breadcrumbs }
        count={ relevantValidationsCount }
      />

      <DynamicEntityIndex
        initialItems={ validations }
        initialTotalPages={ initialTotalPages }
        disableCustomization={ true }
        disableDelete={ true }
        entity={ "tickets_validations" }
        tabs={ showTabs ? tabs : [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderValidation }
        itemsPath={ itemsPath }
        exportPath={ exportPath }
        noResourcesTitle={ Website.translations.noValidations }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        isExportable
        enableDateFilter
        selectAll
        initialTotalCount={ relevantValidationsCount }
        updateTotalCount={ setRelevantValidationsCount }
      />
    </>
  )
}
