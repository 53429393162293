import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import ModalAlert from '../shared/ModalAlert'
import FormInput from '../shared/FormInput'
import SubmitButton from '../shared/SubmitButton'

export default function Index({
  appProps, initialTerminals, initialTotalPages, itemsPath, exportPath, deletePath,
  disableDelete, canCreate, canEdit, selectedColumns, allowCodeUpdate, updateCodePushPath, initialTotalCount
}) {
  const [terminals, setTerminals]                     = useState(initialTerminals)
  const [showModal, setShowModal]                     = useState(false)
  const [showBulkModal, setShowBulkModal]             = useState(false)
  const [fields, setFields]                           = useState({ tid: '', sn: '', device_name: '' })
  const [bulkTerminals, setBulkTerminals]             = useState('')
  const [showCodeUpdateAlert, setShowCodeUpdateAlert] = useState(false)
  const [terminalsCount, setTerminalsCount]           = useState(initialTotalCount)

  const { flashMessage } = appProps

  const actions = []

  if (canEdit) {
    actions.push(
      {
        url: item => {
          const adminEditPath = `/admin/${ appProps.currentLocale }/mids/${ item.subaccountMid }/terminals/${ encodeURIComponent(item.serialNumber) }/edit`
          const letmeinEditPath = `/letmein/${ appProps.currentLocale }/meraccs/${ item.accountApiUid }/mids/${ item.subaccountMid }/terminals/${ encodeURIComponent(item.serialNumber) }/edit`

          const editPath = appProps.merchantsPath ? letmeinEditPath : adminEditPath
          return editPath
        },
        icon: 'fa-pencil'
      }
    )
  }

  const handleChange = (key, e) => {
    setFields(prevState => ({
      ...prevState,
      [key]: e.target.value
    }))
  }

  const handleSubmit = (_e, mode) => {
    const fd = new FormData()
    if (mode === 'singular') {
      fd.append('terminal[tid]', fields.tid)
      fd.append('terminal[sn]', fields.sn)
      fd.append('terminal[device_name]', fields.device_name)
    } else {
      fd.append('bulk_terminals', bulkTerminals)
    }

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        setTerminals(prevState => [ ...prevState, ...res.terminals ])
        setShowModal(false)
        setShowBulkModal(false)
        setFields({ tid: '', sn: '', device_name: '' })
        setBulkTerminals('')
        flashMessage.show(res.success, 'success')
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  const sendCodeUpdate = _e => {
    setShowCodeUpdateAlert(false)

    Rails.ajax({
      type: 'GET',
      url: updateCodePushPath,
      dataType: 'json',
      success: _res => {
        flashMessage.show(Website.translations.codePushUpdateSuccess, 'success')
      }
    })
  }

  function renderTerminal(terminal, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        const output = terminal[key] || '-'

        return (
          <div key={ key } className='item flex-1'>
            <div>
              { output }
            </div>
          </div>
        )
      })}
    </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="medium"
        >
          <FormInput
            label={ Website.translations.columns.tid }
            value={ fields.tid }
            onChange={ e => handleChange('tid', e) }
          />
          <FormInput
            label={ Website.translations.columns.serialNumber}
            value={ fields.sn }
            onChange={ e => handleChange('sn', e) }
          />
          <FormInput
            label={ Website.translations.columns.deviceName}
            value={ fields.device_name }
            onChange={ e => handleChange('device_name', e) }
          />

          <div className='flex-box content-end'>
            <SubmitButton
              value={ Website.translations.save }
              className='btn btn-primary'
              onSubmit={ e => handleSubmit(e, 'singular') }
            />
          </div>
        </Modal>
      }

      { showBulkModal &&
        <Modal
          visible={ showBulkModal }
          closeModal={() => setShowBulkModal(false)}
          mode="large"
        >
          <label className='fs-6 fw-semibold form-label mt-3'>
            { Website.translations.terminalData }
          </label>
          <textarea
            style={ { resize: 'none' } }
            placeholder={ Website.translations.bulkTerminalsDesc }
            rows={ 10 }
            value={ bulkTerminals }
            onChange={ e => setBulkTerminals(e.target.value) }
          />
          <div>
            <SubmitButton
              value={ Website.translations.create }
              className='pointer btn btn-primary m-t-20'
              onSubmit={ e => handleSubmit(e, 'bulk') }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.terminals }
        breadcrumbs={ appProps.breadcrumbs }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
        bulkImport={ canCreate }
        setShowBulkModal={ setShowBulkModal }
        count={ terminalsCount }
      />

      { allowCodeUpdate &&
        <div className='flex-box content-end m-b-20'>
          <button
            className='btn btn-primary'
            onClick={ () => setShowCodeUpdateAlert(true) }
          >
            { Website.translations.sendCodeUpdate }
          </button>
        </div>
      }

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ terminals }
        setInitialItems={ setTerminals }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableDelete={ disableDelete }
        disableCustomization={ true }
        entity={ "terminals" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderTerminal }
        exportPath={ exportPath }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noTerminals }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        isExportable={ true }
        initialTotalCount={ initialTotalCount }
        updateTotalCount={ setTerminalsCount }
      />

      { showCodeUpdateAlert &&
        <ModalAlert
          alert={ Website.translations.codeUpdateAlert }
          acceptText={ Website.translations.ok }
          onClose={ () => setShowCodeUpdateAlert(false) }
          onSelect={ sendCodeUpdate }
        />
      }
    </>
  )
}
