import React, { useState, useEffect, useRef } from 'react'
import FormInput from '../shared/FormInput'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import ModalAlert from '../shared/ModalAlert'
import SaveFixedButton from '../shared/SaveFixedButton'
import TextEditor from '../shared/TextEditor'
import LanguageHandler from '../shared/LanguageHandler'
import Toggle from 'react-toggle'

function formatZones(zones) {
  return zones.reduce((acc, zone) => {
    const tempZone = (({ mapping, ...rest }) => rest)(zone)
    const zoneJson = JSON.parse(zone.mapping)
    const { width, height, offset_x, offset_y, rows, inactive_rows, inactive_seats } = zoneJson
    tempZone.width = width
    tempZone.height = height
    tempZone.offsetX = offset_x
    tempZone.offsetY = offset_y
    tempZone.rows = rows.map((numberOfSeats, index) => ({
      name: `Row #${ index }`,
      seats: {
        number: numberOfSeats,
        inactive: inactive_seats[index] ? inactive_seats[index] : []
      },
      active: !inactive_rows.includes(index)
    }))

    acc.push(tempZone)

    return acc
  }, [])
}

export default function EditSpace({
  appProps, languages, space, updatePath
}) {
  let formattedZones = []
  if (space.zones.length > 0) {
    formattedZones = formatZones(space.zones)
  }
  const [zones, setZones] = useState(formattedZones)
  const [spaceActive, setSpaceActive] = useState(space.active)
  const [currentZoneIndex, setCurrentZoneIndex] = useState(null)
  const [toggledZones, setToggledZones] = useState({})
  const [rowCloneState, setRowCloneState] = useState(null)
  const [rowDeletionState, setRowDeletionState] = useState(null)
  const [zoneCloneState, setZoneCloneState] = useState(null)
  const [zoneDeletionState, setZoneDeletionState] = useState(null)
  const [zoneDragStartCoords, setZoneDragStartCoords] = useState(null)
  const [saveButtonRef, setSaveButtonRef] = useState(null)

  const usedLocales = space.translations.map(translation => translation.locale)
  const initialUsedLanguages = languages.filter(language => usedLocales.indexOf(language.code) > -1 )
  const currentLanguage = initialUsedLanguages.find(language => language.code == appProps.currentLocale) || initialUsedLanguages[0] || languages[0]

  const [usedLanguages, setUsedLanguages]         = useState(initialUsedLanguages.length ? initialUsedLanguages : [currentLanguage])
  const [selectedLanguage, setSelectedLanguage]   = useState(currentLanguage.code)
  const [spaceTranslations, setSpaceTranslations] = useState(() => getInitialTranslations(space))
  const [zoneTranslations, setZoneTranslations]   = useState(() => getInitialZoneTranslations(space))
  const [renderKey, setRenderKey]                 = useState(1)

  const isInitialRender = useRef(true)
  const latestDeletedZoneIndex = useRef(null)

  const [translatableContent, setTranslatableContent] = useState(calculateTranslatableContent())

  function calculateTranslatableContent() {
    return [
      { items: spaceTranslations, setItems: setSpaceTranslations, initialValues: { title: '', description: '' } },
      ...zoneTranslations.map((zoneTranslation, index) => ({
        items: zoneTranslation,
        setItems: newTranslations => setZoneTranslations(prevState => [
          ...prevState.slice(0, index),
          newTranslations,
          ...prevState.slice(index + 1)
        ]),
        initialValues: { title: '' }
      }))
    ]
  }

  useEffect(() => {
    setRenderKey(Math.random())
  }, [selectedLanguage])

  useEffect(() => {
    if (!isInitialRender.current) {
      setZoneTranslations(getInitialZoneTranslations({ zones: zones }))
      setTranslatableContent(calculateTranslatableContent())
    }

    latestDeletedZoneIndex.current = null
  }, [zones.filter(zone => !zone._destroy).length])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      saveButtonRef.setSaveableStatus()
    }
  }, [zones])

  function getInitialTranslations(item) {
    const initialTranslations = {}
    item.translations.map(translation => {
      initialTranslations[translation.locale] = {
        title: translation.title,
        description: translation.description,
        id: translation.id
      }
    })

    return initialTranslations
  }

  function getInitialZoneTranslations(item) {
    const initialZoneTranslations = []

    item.zones.filter(zone => !zone._destroy).forEach((zone, index) => {
      initialZoneTranslations.push({})

      if (zone.translations) {
        zone.translations.forEach(translation => {
          initialZoneTranslations[index][translation.locale] = {
            title: translation.title,
            id: translation.id
          }
        })
      } else {
        if (latestDeletedZoneIndex.current !== null) {
          if (latestDeletedZoneIndex.current > index) {
            initialZoneTranslations[index] = zoneTranslations[index]
          } else {
            initialZoneTranslations[index] = zoneTranslations[index + 1]
          }
        } else {
          if (index > zoneTranslations.length - 1) {
            initialZoneTranslations[index] = {
              en: { title: `Zone #${ index }` },
              el: { title: `Ζώνη #${ index }` }
            }
          } else {
            initialZoneTranslations[index] = zoneTranslations[index]
          }
        }
      }
    })
    return initialZoneTranslations
  }

  const extraRemoveLanguageCallback = _locale => {
    saveButtonRef.setSaveableStatus()
  }

  const handleSpaceTranslationChange = (input, key) => {
    const _translations = { ...spaceTranslations }

    if (key === 'description') {
      _translations[selectedLanguage][key] = input
    } else {
      _translations[selectedLanguage][key] = input.target.value
    }

    setSpaceTranslations(_translations)
    saveButtonRef.setSaveableStatus()
  }

  const handleZoneTranslationChange = (e, index) => {
    const _translations = { ...zoneTranslations[index] }
    _translations[selectedLanguage].title = e.target.value

    setZoneTranslations(prevState => [
      ...prevState.slice(0, index),
      _translations,
      ...prevState.slice(index + 1)
    ])
    saveButtonRef.setSaveableStatus()
  }

  const handleToggleZone = index => {
    setToggledZones(prevState => ({ ...prevState, [index]: !prevState[index] }))
  }

  const handleAddZone = () => {
    const newZone = {
      rows: [],
      active: true,
      width: 0,
      height: 0,
      offsetX: 0,
      offsetY: 0
    }

    setToggledZones(prevState => ({ ...prevState, [zones.length]: true }))
    setZones(prevState => [...prevState, newZone])
  }

  const handleCloneZone = _e => {
    const { zoneIndex, times } = zoneCloneState
    const newZones = []
    const relevantZone = zones[zoneIndex]

    for (let i = 0; i < times; i++) {
      newZones.push({
        ...relevantZone,
        name: `Zone #${ zones.length + i }`,
      })
    }

    setZones(prevState => [...prevState, ...newZones])

    setZoneCloneState(null)
  }

  const handleDeleteZone= () => {
    let { zoneIndex } = zoneDeletionState
    latestDeletedZoneIndex.current = zoneIndex

    let trueIndex
    let numberOfNonDeletedZonesEncountered = 0
    let i = 0

    while(numberOfNonDeletedZonesEncountered <= zoneIndex) {
      if (!zones[i]._destroy) {
        numberOfNonDeletedZonesEncountered++
      }

      if (numberOfNonDeletedZonesEncountered === zoneIndex + 1) {
        trueIndex = i
      }

      i++
    }

    setZones(prevState => [
      ...prevState.slice(0, trueIndex),
      { ...prevState[trueIndex], _destroy: true },
      ...prevState.slice(trueIndex + 1),
    ])

    setZoneDeletionState(null)
  }

  const handleAddRow = index => {
    const newRow = {
      name: `Row #${ zones[index].rows.length }`,
      seats: {
        number: 0,
        inactive: []
      },
      active: true
    }

    setZones(prevState => [
      ...prevState.slice(0, index),
      {
        ...prevState[index],
        rows: [...prevState[index].rows, newRow]
      },
      ...prevState.slice(index + 1)
    ])
  }

  const handleCloneRow = _e => {
    const { zoneIndex, rowIndex, times } = rowCloneState
    const newRows = []
    const relevantRow = zones[zoneIndex].rows[rowIndex]

    for (let i = 0; i < times; i++) {
      newRows.push({
        name: `Row #${ zones[zoneIndex].rows.length + i }`,
        seats: relevantRow.seats,
        active: relevantRow.active
      })
    }

    setZones(prevState => [
      ...prevState.slice(0, zoneIndex),
      {
        ...prevState[zoneIndex],
        rows: [...prevState[zoneIndex].rows, ...newRows]
      },
      ...prevState.slice(zoneIndex + 1)
    ])

    setRowCloneState(null)
  }

  const handleDeleteRow = () => {
    const { zoneIndex, rowIndex } = rowDeletionState

    setZones(prevState => [
      ...prevState.slice(0, zoneIndex),
      {
        ...prevState[zoneIndex],
        rows: [
          ...prevState[zoneIndex].rows.slice(0, rowIndex),
          ...prevState[zoneIndex].rows.slice(rowIndex + 1).map(row => ({ ...row, name: `Row #${ parseInt(row.name.split('#')[1]) - 1 }` }))
        ]
      },
      ...prevState.slice(zoneIndex + 1)
    ])

    setRowDeletionState(null)
  }

  const handleZoneFieldChange = (e, index, key) => {
    let change

    if (key === 'active') {
      change = !zones[index].active
    } else {
      change = e.target.value
    }

    setZones(prevState => [
      ...prevState.slice(0, index),
      {
        ...prevState[index],
        [key]: change
      },
      ...prevState.slice(index + 1)
    ])
  }

  const handleRowFieldChange = (e, zoneIndex, rowIndex, key) => {
    let change

    if (key === 'active') {
      change = !zones[zoneIndex].rows[rowIndex].active
    } else {
      change = e.target.value
    }

    if (key === 'seatsNumber') {
      setZones(prevState => [
        ...prevState.slice(0, zoneIndex),
        {
          ...prevState[zoneIndex],
          rows: [
            ...prevState[zoneIndex].rows.slice(0, rowIndex),
            {
              ...prevState[zoneIndex].rows[rowIndex],
              seats: {
                ...prevState[zoneIndex].rows[rowIndex].seats,
                number: change
              }
            },
            ...prevState[zoneIndex].rows.slice(rowIndex + 1)
          ]
        },
        ...prevState.slice(zoneIndex + 1)
      ])
    } else {
      setZones(prevState => [
        ...prevState.slice(0, zoneIndex),
        {
          ...prevState[zoneIndex],
          rows: [
            ...prevState[zoneIndex].rows.slice(0, rowIndex),
            {
              ...prevState[zoneIndex].rows[rowIndex],
              [key]: change
            },
            ...prevState[zoneIndex].rows.slice(rowIndex + 1)
          ]
        },
        ...prevState.slice(zoneIndex + 1)
      ])
    }
  }

  const handleSeatClick = (rowIndex, seatIndex) => {
    setZones(prevState => [
      ...prevState.slice(0, currentZoneIndex),
      {
        ...prevState[currentZoneIndex],
        rows: prevState[currentZoneIndex].rows.reverse().map((row, index) => {
          return index === rowIndex ? {
            ...row,
            seats: {
              ...row.seats,
              inactive: row.seats.inactive.includes(seatIndex) ? row.seats.inactive.filter(idx => idx !== seatIndex) : [...row.seats.inactive, seatIndex]
            }
          } : row
        }).reverse()
      },
      ...prevState.slice(currentZoneIndex + 1)
    ])
  }

  const renderCurrentZone = () => {
    const relevantZone = zones[currentZoneIndex]
    const maxNumberOfSeats = relevantZone.rows.reduce((acc, curr) => {
      const numberOfSeats = +curr.seats.number

      return numberOfSeats > acc ? numberOfSeats : acc
    }, 0)

    const maximumWidth = maxNumberOfSeats * 20 + (maxNumberOfSeats + 1) * 2

    return (
      <div className='m-t-20 row-boxes-wrapper' style={{ height: `${ 40 * relevantZone.rows.length }px` }}>
        { relevantZone.rows.length > 0 && relevantZone.rows.reverse().map((row, index) => (
          <div
            key={ index }
            className='flex-box'
            style={{ gap: '10px' }}
          >
            <div
              className='flex-box items-center content-center'
              style={{ flexBasis: '10%', flexShrink: 0 }}
            >
              { row.name }
            </div>
            <div
              className={ `row-box ${ !row.active ? 'inactive-row' : '' }` }
              style={{ height: '40px', width: maximumWidth }}
            >
              { Array(parseInt(row.seats.number)).fill(0).map((_, arrIndex) => (
                <div
                  key={ arrIndex }
                  className={ `flex-box items-center content-center seat ${ row.seats.inactive.includes(arrIndex) ? 'inactive-seat' : 'active-seat' }` }
                  style={{ height: '20px', width: '20px' }}
                  onClick={ _e => row.active ? handleSeatClick(index, arrIndex) : '' }
                >
                  { arrIndex }
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const handleDragEnd = e => {
    const diffX = e.clientX - zoneDragStartCoords.x
    const diffY = e.clientY - zoneDragStartCoords.y
    const relevantIndex = zoneDragStartCoords.zoneIndex

    setZones(prevState => [
      ...prevState.slice(0, relevantIndex),
      {
        ...prevState[relevantIndex],
        offsetX: +prevState[relevantIndex].offsetX + diffX,
        offsetY: +prevState[relevantIndex].offsetY + diffY
      },
      ...prevState.slice(relevantIndex + 1)
    ])

    setZoneDragStartCoords(null)
  }

  const save = () => {
    const fd = new FormData()
    let numberOfDeletedZonesEncountered = 0

    Object.keys(spaceTranslations).forEach((locale, index) => {
      fd.append(`space[translations_attributes][${ index }][title]`, spaceTranslations[locale].title || '')
      fd.append(`space[translations_attributes][${ index }][description]`, spaceTranslations[locale].description || '')
      fd.append(`space[translations_attributes][${ index }][locale]`, locale)

      if (spaceTranslations[locale].id) {
        fd.append(`space[translations_attributes][${ index }][id]`, spaceTranslations[locale].id)
      }
      if (spaceTranslations[locale]._destroy) {
        fd.append(`space[translations_attributes][${ index }][_destroy]`, spaceTranslations[locale]._destroy)
      }
    })

    fd.append('space[active]', spaceActive)
    zones.forEach((zone, index) => {
      if (zone._destroy) {
        numberOfDeletedZonesEncountered++
        if (zone.id) {
          fd.append(`space[space_zones_attributes][${ index }][_destroy]`, true)
        }
      } else {
        fd.append(`space[space_zones_attributes][${ index }][active]`, zone.active)
        const mapping = {
          height: zone.height,
          width: zone.width,
          offset_x: zone.offsetX,
          offset_y: zone.offsetY,
          rows: zone.rows.map(row => row.seats.number),
          inactive_rows: zone.rows.filter(row => !row.active).map(row => +row.name.split('#')[1]),
          inactive_seats: zone.rows.reduce((acc, row) => {
            if (row.seats.inactive.length > 0) {
              acc[+row.name.split('#')[1]] = row.seats.inactive
            }

            return acc
          }, {})
        }

        mapping.number_of_available_seats = mapping.rows.reduce((acc, seats) => acc += +seats, 0)

        mapping.inactive_rows.forEach(rowIndex => {
          mapping.number_of_available_seats -= +mapping.rows[rowIndex]
        })

        Object.entries(mapping.inactive_seats).forEach(entryArray => {
          if (!mapping.inactive_rows.includes(+entryArray[0])) {
            mapping.number_of_available_seats -= entryArray[1].length
          }
        })

        fd.append(`space[space_zones_attributes][${ index }][mapping]`, JSON.stringify(mapping))

        Object.keys(zoneTranslations[index - numberOfDeletedZonesEncountered]).forEach((locale, idx) => {
          fd.append(`space[space_zones_attributes][${ index }][translations_attributes][${ idx }][title]`, zoneTranslations[index - numberOfDeletedZonesEncountered][locale].title || '')
          fd.append(`space[space_zones_attributes][${ index }][translations_attributes][${ idx }][locale]`, locale)

          if (zoneTranslations[index - numberOfDeletedZonesEncountered][locale].id) {
            fd.append(`space[space_zones_attributes][${ index }][translations_attributes][${ idx }][id]`, zoneTranslations[index - numberOfDeletedZonesEncountered][locale].id)
          }
          if (zoneTranslations[index - numberOfDeletedZonesEncountered][locale]._destroy) {
            fd.append(`space[space_zones_attributes][${ index }][translations_attributes][${ idx }][_destroy]`, zoneTranslations[index - numberOfDeletedZonesEncountered][locale]._destroy)
          }
        })
      }

      if (zone.id) {
        fd.append(`space[space_zones_attributes][${ index }][id]`, zone.id)
      }
    })

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        saveButtonRef.setSuccessStatus()
        setZones(formatZones(res.space.zones))
        setSpaceActive(res.space.active)
        setSpaceTranslations(getInitialTranslations(res.space))
        setZoneTranslations(getInitialZoneTranslations(res.space))
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  return (
    <>
      <CreateButton
        pageTitle={ space.title }
      />

      <div>
        <div
          className='m-b-20 card'
          style={{
            height: `${ space.height }px`,
            width: `${ space.width }px`,
            position: 'relative'
          }}
        >
          { zones.length > 0 && zones.filter(zone => !zone._destroy).map((zone, index) => (
            <div
              key={ index }
              className={ `flex-box items-center content-center zone-box ${ zone.active ? 'zone-active' : 'zone-inactive' }` }
              style={{
                height: `${ zone.height }px`,
                width: `${ zone.width }px`,
                left: `${ zone.offsetX }px`,
                top: `${ zone.offsetY }px`,
              }}
              onClick={ _e => setCurrentZoneIndex(index) }
              draggable
              onDragStart={ e => setZoneDragStartCoords({ x: e.clientX, y: e.clientY, zoneIndex: index }) }
              onDragEnd={ handleDragEnd }
            >
              { zoneTranslations?.[index]?.[selectedLanguage]?.title || '' }
            </div>
          ))}
        </div>

        <div className='card'>
          <div className='card-content'>
            <div className='card-body'>
              <LanguageHandler
                languages={ languages }
                usedLanguages={ usedLanguages }
                setUsedLanguages={ setUsedLanguages }
                selectedLanguage={ selectedLanguage }
                setSelectedLanguage={ setSelectedLanguage }
                translatableContent={ translatableContent }
                extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
              />

              <FormInput
                label={ Website.translations.columns.title }
                value={ spaceTranslations[selectedLanguage]?.title || '' }
                onChange={ e => handleSpaceTranslationChange(e, 'title') }
              />

              <div className={ 'flex-box flex-column m-b-20' }>
                <label className='fs-6 fw-semibold form-label mt-3'>
                  { Website.translations.columns.description }
                </label>
                <TextEditor
                  key={ renderKey }
                  content={ spaceTranslations[selectedLanguage]?.description || '' }
                  setContent={ html => handleSpaceTranslationChange(html, 'description') }
                />
              </div>

              <div className='flex-box content-space-between' style={{ gap: '20px' }}>
                <div className='flex-box items-center content-center'>{ Website.translations.activateSpaceHelpText }</div>
                <div className="flex-box flex-column items-center content-space-between">
                  <label className='fs-6 fw-semibold form-label mt-3'>
                    { Website.translations.spaceActive }
                  </label>
                  <div>
                    <Toggle
                      icons={{ unchecked: null }}
                      className='react-toggle small'
                      checked={ spaceActive }
                      onChange={ _e => { saveButtonRef.setSaveableStatus(); setSpaceActive(prevState => !prevState) } }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-header border-0'>
            <div className='flex-box content-end' style={{ width: '100%' }}>
              <button
                className='btn btn-primary'
                onClick={ handleAddZone }
                style={{ height: '50px' }}
              >
                { Website.translations.addZone }
              </button>
            </div>
          </div>

          <div className='card-content'>
            <div className='card-body'>
              <div className='row'>
                { zones.length > 0 && zones.filter(zone => !zone._destroy).map((zone, index) => (
                  <div key={ index }>
                    <div
                      onClick={ _e => handleToggleZone(index) }
                      style={{ cursor: 'pointer' }}
                      className='flex-box items-center content-space-between p-t-15 p-b-15'
                    >
                      <div>{ zoneTranslations?.[index]?.[selectedLanguage]?.title || '' }</div>
                      <div className='flex-box items-center content-end' style={{ flexBasis: '20%' }}>
                        <i
                          className={ `fa-light fa-${ toggledZones[index] ? 'chevron-up' : 'chevron-down' }` }
                          style={{ width: 45, height: 15 }}
                        />
                      </div>
                    </div>

                    { toggledZones[index] &&
                      <div className='row'>
                        <div
                          className='flex-box m-b-20'
                          style={{ gap: '20px' }}
                        >
                          <FormInput
                            label={ Website.translations.zoneName }
                            value={ zoneTranslations[index] ? (zoneTranslations[index][selectedLanguage]?.title || '') : '' }
                            onChange={ e => handleZoneTranslationChange(e, index) }
                          />
                          <div style={{ flexBasis: '10%' }}>
                            <FormInput
                              label={ Website.translations.width }
                              value={ zone.width }
                              onChange={ e => handleZoneFieldChange(e, index, 'width') }
                            />
                          </div>
                          <div style={{ flexBasis: '10%' }}>
                            <FormInput
                              label={ Website.translations.height }
                              value={ zone.height }
                              onChange={ e => handleZoneFieldChange(e, index, 'height') }
                            />
                          </div>
                          <FormInput
                            label={ Website.translations.offsetX }
                            value={ zone.offsetX }
                            onChange={ e => handleZoneFieldChange(e, index, 'offsetX') }
                          />
                          <FormInput
                            label={ Website.translations.offsetY }
                            value={ zone.offsetY }
                            onChange={ e => handleZoneFieldChange(e, index, 'offsetY') }
                          />
                          <div className="flex-box flex-column items-center content-space-between">
                            <label className='fs-6 fw-semibold form-label mt-3'>
                              { Website.translations.active }
                            </label>
                            <div>
                              <Toggle
                                icons={{ unchecked: null }}
                                className='react-toggle small'
                                checked={ zone.active }
                                onChange={ e => handleZoneFieldChange(e, index, 'active') }
                              />
                            </div>
                          </div>
                          <div className='flex-box' style={{ gap: '20px' }}>
                            <div
                              className='flex-box items-center'
                              style={{ cursor: 'pointer' }}
                              onClick={ _e => setZoneCloneState({ zoneIndex: index, times: 1 }) }
                            >
                              <i
                                className='fa-light fa-clone'
                              />
                            </div>
                            <div
                              className='flex-box items-center'
                              style={{ cursor: 'pointer' }}
                              onClick={ _e => setZoneDeletionState({ zoneIndex: index }) }
                            >
                              <i
                                className='fa-light fa-trash'
                              />
                            </div>
                          </div>
                        </div>

                        <div className='flex-box content-end p-l-20 p-r-20 m-b-20'>
                          <button
                            className='btn btn-primary'
                            onClick={ _e => handleAddRow(index) }
                          >
                            { Website.translations.addRow }
                          </button>
                        </div>

                        { zone.rows.length > 0 && zone.rows.map((row, rowIndex) => (
                          <div key={ rowIndex } className='p-l-20 p-r-20'>
                            <div
                              className='flex-box content-space-between p-b-10 p-t-10'
                              style={{ borderBottom: '1px solid #555', gap: '20px' }}
                            >
                              <div className={ 'flex-box flex-column'}>
                                <label className='fs-6 fw-semibold form-label mt-3'>
                                  { Website.translations.columns.name }
                                </label>
                                <div
                                  style={{ width: '100%', padding: '0.775rem 1rem', fontSize: '1.1rem', fontWeight: 500, lineHeight: 1.5, borderRadius: '0.475rem' }}
                                >
                                  { row.name }
                                </div>
                              </div>
                              <FormInput
                                label={ Website.translations.numberOfSeats }
                                value={ row.seats.number }
                                onChange={ e => handleRowFieldChange(e, index, rowIndex, 'seatsNumber') }
                              />
                              <div className="flex-box flex-column items-center content-space-between">
                                <label className='fs-6 fw-semibold form-label mt-3'>
                                  { Website.translations.active }
                                </label>
                                <div>
                                  <Toggle
                                    icons={{ unchecked: null }}
                                    className='react-toggle small'
                                    checked={ row.active }
                                    onChange={ e => handleRowFieldChange(e, index, rowIndex, 'active') }
                                  />
                                </div>
                              </div>
                              <div className='flex-box' style={{ gap: '20px' }}>
                                <div
                                  className='flex-box items-center'
                                  style={{ cursor: 'pointer' }}
                                  onClick={ _e => setRowCloneState({ zoneIndex: index, rowIndex: rowIndex, times: 1 }) }
                                >
                                  <i
                                    className='fa-light fa-clone'
                                  />
                                </div>
                                <div
                                  className='flex-box items-center'
                                  style={{ cursor: 'pointer' }}
                                  onClick={ _e => setRowDeletionState({ zoneIndex: index, rowIndex: rowIndex }) }
                                >
                                  <i
                                    className='fa-light fa-trash'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      { currentZoneIndex !== null &&
        <Modal
          visible={ true }
          closeModal={ () => setCurrentZoneIndex(null) }
          mode='xlarge'
        >
          { renderCurrentZone() }
        </Modal>
      }

      { !!rowCloneState &&
        <Modal
          visible={ true }
          closeModal={ () => setRowCloneState(null) }
          mode='small centered'
          action={ handleCloneRow }
          actionText={ Website.translations.create }
        >
          <div>
            <FormInput
              type='number'
              label={ Website.translations.cloneRowHelpText }
              value={ rowCloneState.times }
              onChange={ e => setRowCloneState(prevState => ({ ...prevState, times: e.target.value })) }
            />
          </div>
        </Modal>
      }

      { !!zoneCloneState &&
        <Modal
          visible={ true }
          closeModal={ () => setZoneCloneState(null) }
          mode='small centered'
          action={ handleCloneZone }
          actionText={ Website.translations.create }
        >
          <div>
            <FormInput
              type='number'
              label={ Website.translations.cloneZoneHelpText }
              value={ zoneCloneState.times }
              onChange={ e => setZoneCloneState(prevState => ({ ...prevState, times: e.target.value })) }
            />
          </div>
        </Modal>
      }

      { !!rowDeletionState &&
        <ModalAlert
          alert={ Website.translations.rowDeletionAlert }
          onSelect={ handleDeleteRow }
          onClose={ () => setRowDeletionState(null) }
        />
      }

      { !!zoneDeletionState &&
        <ModalAlert
          alert={ Website.translations.zoneDeletionAlert }
          onSelect={ handleDeleteZone }
          onClose={ () => setZoneDeletionState(null) }
        />
      }

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.space }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
