import React, { useState, useEffect } from 'react'
import FormInput from '../shared/FormInput'
import SaveFixedButton from '../shared/SaveFixedButton'
import Administrators from '../administrators/Index'
import CreateButton from '../shared/CreateButton'

export default function EditMerchantAccount({
  appProps, merchantAccount, updatePath, administratorsPath, deleteAdministratorsPath, invitableRoles,
  invitationPath, reinvitePath, canCreateAdministrator, canEditAdministrator, canDeleteAdministrator, canEditTitle, promoContentsPath
}) {
  const [account, setAccount]                           = useState(merchantAccount)
  const [title, setTitle]                               = useState(account.title)
  const [saveButtonRef, setSaveButtonRef]               = useState(null)
  const [administrators, setAdministrators]             = useState(null)
  const [totalPages, setTotalPages]                     = useState(1)
  const [selectedColumns, setSelectedColumns]           = useState([])
  const [emailSettings, setEmailSettings]               = useState(merchantAccount.settings.filter(item => item.key.startsWith('smtp_')))
  const [emailSettingsChanged, setEmailSettingsChanged] = useState(false)

  useEffect(() => {
    Rails.ajax({
      type: 'GET',
      url: administratorsPath,
      dataType: 'json',
      success: res => {
        setAdministrators(res.items)
        setTotalPages(res.totalPages)
        setSelectedColumns(res.selectedColumns)
      }
    })
  }, [])

  const renderEmailSetting = setting => {
    const label = setting.key.split('_').map(item => item[0].toUpperCase() + item.slice(1)).join(' ')
    const type = label === 'Smtp Password' ? 'password' : 'text'

    return (
      <FormInput
        key ={ setting.id }
        label={ label }
        type={ type }
        value={ setting.value }
        onChange={ e => handleSettingChange(e, setting.key) }
      />
    )
  }

  const handleSettingChange = (e, key) => {
    const settingIndex = emailSettings.findIndex(item => item.key === key)

    setEmailSettings(prevState => ( [
      ...prevState.slice(0, settingIndex),
      { ...prevState[settingIndex], value: e.target.value },
      ...prevState.slice(settingIndex + 1)
    ]))

    setEmailSettingsChanged(true)
    saveButtonRef.setSaveableStatus()
  }

  const save = () => {
    const fd = new FormData()
    fd.append('merchant_account[title]', title)
    emailSettings.forEach((setting, index) => {
      fd.append(`merchant_account[settings_attributes][${ index }][id]`, setting.id)
      fd.append(`merchant_account[settings_attributes][${ index }][key]`, setting.key)
      fd.append(`merchant_account[settings_attributes][${ index }][value]`, setting.value)
    })
    fd.append('validate_email_settings', emailSettingsChanged)

    saveButtonRef.setSavingStatus()

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        saveButtonRef.setSuccessStatus()
        setAccount(res.merchantAccount)
        setEmailSettingsChanged(false)
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  const handleChange = e => {
    setTitle(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  return (
    <>
      <CreateButton
        pageTitle={ merchantAccount.title }
        breadcrumbs={ appProps.breadcrumbs }
      />

      { canEditTitle &&
        <>
          <div className='m-b-30'>
            <h3>{ Website.translations.columns.merchantTitle }</h3>
          </div>
          <div className='card m-b-30'>
            <FormInput
              label={ Website.translations.columns.merchantTitle }
              value={ title }
              onChange={ handleChange }
            />
          </div>
        </>
      }

      <div className='m-b-30'>
        <h3>{ Website.translations.emailSettings }</h3>
      </div>

      <div className='card m-b-30'>
        { emailSettings.map(renderEmailSetting) }
      </div>

      <hr />

      { administrators !== null &&
        <Administrators
          appProps={ appProps }
          initialAdministrators={ administrators }
          invitableRoles={ invitableRoles }
          invitationPath={ invitationPath }
          deletePath={ deleteAdministratorsPath }
          reinvitePath={ reinvitePath }
          itemsPath={ administratorsPath }
          initialTotalPages={ totalPages }
          canCreate={ canCreateAdministrator }
          canEdit={ canEditAdministrator }
          canDelete={ canDeleteAdministrator }
          selectedColumns={ selectedColumns }
          accountType={ 'MerchantAccount'}
          merchantAccountApiUid={ merchantAccount.apiUid }
          breadcrumbs={ null }
        />
      }

      <div className='flex-box content-end m-t-20'>
        <a 
          className='btn btn-primary'
          href={ promoContentsPath }
          target='_blank'
        >
          { Website.translations.promoContent }
        </a>
      </div>

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.merchantAccount }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
