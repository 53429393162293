import React, { useState } from 'react'
import Modal from '../shared/Modal'
import EntityTile from '../shared/EntityTile'
import CreateButton from '../shared/CreateButton'

export default function MerchantSubaccount({
  appProps, subaccount, editPath, deletePath, canEdit, canDelete, canViewAdmins,
  terminalsPath, ticketsPath, locationsPath, locationGroupsPath,
  taxesPath, seasonsPath, ordersPath, receiptsPath, administratorsPath, filesPath,
  stockRulesPath, stockType, ticketsValidationsPath, promoContentsPath
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const placeholderAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2nNUXQ8TRIbctxR4bGhWkQd5mrIeu1okIyte3_rz0HSVf6hctJAXHOwrUidaXMlOtCFA&usqp=CAU'

  const handleDelete = () => {
    const fd = new FormData()
    fd.append('merchant_subaccounts', [subaccount.id])

    Rails.ajax({
      type: 'DELETE',
      url: deletePath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
    })
  }

  const renderDeleteModal = () => (
    <Modal
      visible={ showDeleteModal }
      closeModal={() => setShowDeleteModal(false)}
      mode="small"
    >
      <div className='flex-box flex-column items-center content-space-between'>
        <h4>
          { Website.translations.areYouSure }
        </h4>
        <div className='flex-box items-center content-center m-t-40'>
          <button className='btn btn-primary' onClick={() => setShowDeleteModal(false)} >
            { Website.translations.goBack}
          </button>
          <button className='btn' onClick={handleDelete} >
            { Website.translations.continue}
          </button>
        </div>
      </div>
    </Modal>
  )

  let tiles = [
    { icon: 'mobile-screen', path: terminalsPath, title: Website.translations.terminals, description: Website.translations.terminalsDescription, count: subaccount.terminalsCount, enabled: appProps.canManageTerminals },
    { icon: 'ticket', path: ticketsPath, title: Website.translations.tickets, description: Website.translations.ticketsDescription, count: subaccount.ticketsCount, enabled: true },
    { icon: 'location-dot', path: locationsPath, title: Website.translations.locations, description: Website.translations.locationsDescription, count: subaccount.locationsCount, enabled: true },
    { icon: 'bullseye', path: locationGroupsPath, title: Website.translations.locationGroups, description: Website.translations.locationGroups, count: subaccount.locationGroupsCount, enabled: true },
    { icon: 'percent', path: taxesPath, title: Website.translations.tax, description: Website.translations.taxDescription, count: subaccount.taxesCount, enabled: true },
    { icon: 'calendar-days', path: seasonsPath, title: Website.translations.seasons, description: Website.translations.seasons, count: subaccount.seasonsCount, enabled: true },
    { icon: 'hand-holding-box', path: ordersPath, title: Website.translations.orders, description: Website.translations.ordersDescription, count: subaccount.ordersCount, enabled: appProps.canManageOrders || appProps.canReadOrders },
    { icon: 'receipt', path: receiptsPath, title: Website.translations.receipts, description: Website.translations.receiptsDescription, count: subaccount.receiptsCount, enabled: appProps.canReadReceipts },
    { icon: 'photo-film', path: filesPath, title: Website.translations.media.files, description: Website.translations.media.filesDescription, count: subaccount.filesCount, enabled: true },
    { icon: 'shelves', path: stockRulesPath, title: Website.translations.stockRules, description: Website.translations.stockRulesDescription, count: subaccount.stockRulesCount, enabled: true },
    { icon: 'qrcode', path: ticketsValidationsPath, title: Website.translations.validations, description: Website.translations.validationsDescription, count: subaccount.validationsCount, enabled: appProps.canReadValidations || appProps.canManageValidations },
    { icon: 'megaphone', path: promoContentsPath, title: Website.translations.promoContent, description: Website.translations.promoContentDescription, count: subaccount.promoContentsCount, enabled: appProps.canReadPromoContents || appProps.canManagePromoContents }
  ]

  if (stockType === 'Stock::CustomSpace') {
    tiles = tiles.filter(el => !['location-dot', 'bullseye', 'calendar-days', 'shelves'].includes(el.icon))
  }

  if (canViewAdmins) {
    tiles.push({ icon: 'user-tie', path: administratorsPath, title: Website.translations.administrators, description: Website.translations.administratorsDescription, count: subaccount.administratorsCount, enabled: canViewAdmins })
  }

  if (stockType === 'Stock::Ferry') {
    tiles = tiles.filter(el => !['ticket', 'percent', 'location-dot', 'bullseye', 'calendar-days', 'shelves'].includes(el.icon))
  }

  return (
    <>
      { showDeleteModal && renderDeleteModal() }
      <CreateButton
        pageTitle={ subaccount.internalName ? subaccount.internalName : subaccount.mid }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">

          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-15">

              <div className="d-flex flex-center flex-column mb-5">

                <div className="symbol symbol-100px symbol-circle mb-7" style={{ backgroundColor: '#ffffff' }}>
                  <img src={ subaccount.logo || placeholderAvatar } alt="image" />
                </div>

                <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">{ subaccount.mid }</a>

                {/*<div className="fs-5 fw-semibold text-muted mb-6">Belongs to ODAP</div>*/}

                <div className="d-flex flex-center m-t-20">

                  <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                    <div className="fs-4 fw-bold text-gray-700">
                      <span className="w-75px">{ subaccount.ordersCount }</span>
                    </div>
                    <div className="fw-semibold text-muted">{ Website.translations.orders }</div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                    <div className="fs-4 fw-bold text-gray-700">
                      <span className="w-50px">{ subaccount.ticketsCount }</span>
                    </div>
                    <div className="fw-semibold text-muted">{ Website.translations.tickets }</div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                    <div className="fs-4 fw-bold text-gray-700">
                      <span className="w-50px">{ subaccount.terminalsCount }</span>
                    </div>
                    <div className="fw-semibold text-muted">{ Website.translations.terminals }</div>
                  </div>
                </div>

              </div>


              <div className="d-flex flex-stack fs-4 py-3">
                <div className="fw-bold rotate collapsible">
                  { Website.translations.details }
                </div>
                { canEdit &&
                  <a className="btn btn-sm btn-light-primary" href={`${editPath}`} >
                    { Website.translations.edit }
                  </a>
                }
              </div>

              <div className="separator separator-dashed my-3"></div>

              <div id="kt_customer_view_details" className="collapse show">
                <div className="py-5 fs-6">

                  <div className="fw-bold mt-5">{ Website.translations.columns.mid }</div>
                  <div className="text-gray-600">{ subaccount.mid }</div>

                </div>
              </div>

              <div className="separator separator-dashed my-3"></div>

              <div>
                { canDelete &&
                  <button className='btn btn-sm btn-light-primary' onClick={() => setShowDeleteModal(true)} >
                    { Website.translations.delete }
                  </button>
                }
              </div>

            </div>

          </div>


        </div>

        <div className="flex-lg-row-fluid ms-lg-15">
          <EntityTile
            appProps={ appProps }
            tiles={ tiles }
          />
        </div>
      </div>
    </>
  )
}
