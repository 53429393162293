import React, { useState } from 'react'
import { DropDownMenuStyles } from './DropDownMenuStyles'

export default function DropDownMenu({ items, title }) {
  const [openMenu, setOpenMenu] = useState(false)

  if (items.find( action => action.permissions )) {
    return (
      <div style={ DropDownMenuStyles.menuWrapper }>
        { openMenu &&
          <div style={ DropDownMenuStyles.menuOverlay } onClick={ ()=> setOpenMenu( false ) }></div>
        }
        <div className='flex-box items-center btn' style={ DropDownMenuStyles.toggleButton } onClick={ ()=> setOpenMenu( prevState => !prevState ) }>
          <div className='m-r-5'>{ title || ReactiveCms.translations.choose_action }</div>
          <i className={`fa-light ${ openMenu ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
        </div>
        { openMenu &&
          <div style={ DropDownMenuStyles.menu }>
            <div className='flex-box flex-column' style={ DropDownMenuStyles.menuItems }>
              { items.map( (item, index)=> {
                return (
                  <React.Fragment key={ index }>
                    { item.permissions &&
                      <div  className='pointer flex-box items-center content-space-between'
                            style={ DropDownMenuStyles.menuItem }
                            onClick={ ()=> { item.action(); setOpenMenu(false)} }>
                        { item.icon && <i style={ DropDownMenuStyles.icon } className={`fa-light ${item.icon} `} /> }

                        <div style={ DropDownMenuStyles.optionText }>
                          { item.text }
                        </div>
                      </div>
                    }
                  </React.Fragment>
                )
              })}

            </div>
          </div>
        }
      </div>
    )

  } else {
    return null
  }

}
