import React, { useState, useEffect } from "react"
import ModalAlert from "./ModalAlert"

export default function Sidenav({ appProps }) {
  const [sidebarOpen, setSidebarOpen]         = useState(true)
  const [showLogoutAlert, setShowLogoutAlert] = useState(false)

  const { flashMessage } = appProps

  useEffect( ()=> {
    if ( appProps.currentDevice != 'desktop' ) {
      setSidebarOpen(false)
    }
  },[])

  function handleOpenSidebar() {
    const wrapper = document.getElementById('kt_app_wrapper')
    if ( sidebarOpen ) {
      wrapper.style.marginLeft = 0
    } else {
      wrapper.style.marginLeft = 'calc(var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px))'
    }
    setSidebarOpen( prevState => !prevState )
  }

  const handleLogout = () => {
    Rails.ajax({
      type: 'DELETE',
      url: appProps.logoutPath,
      success: _res => {
        location.href = appProps.adminPath
      },
      error: _res => {
        setShowLogoutAlert(false)
        flashMessage.show(Website.translations.somethingWentWrong, 'error')
      }
    })
  }

  return (
    <>
      <div onClick={ ()=> handleOpenSidebar() } style={{ position: 'fixed', top: 23, left: 15, zIndex: 110 }}>
        <i className="pointer fa-solid fa-bars" style={{ fontSize: 20 }}></i>
      </div>
      { sidebarOpen &&
        <div id="kt_app_sidebar" className="app-sidebar flex-column">
          <div className="app-sidebar-logo p-l-40" id="kt_app_sidebar_logo">
            <a href={appProps.adminPath}>
              <img alt="Logo" src={ appProps.logo } className="h-40px" />
            </a>
          </div>
          <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
            <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-5">
              <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="kt_app_sidebar_menu">
                <div className="menu-item pt-5">
                  <div className="menu-content">
                    <span className="menu-heading fw-bold text-uppercase fs-7">Main</span>
                  </div>
                </div>

                <div className="menu-item menu-accordion">
                  <a className="menu-link" href={ appProps.adminPath }>
                    <span className="menu-icon">
                      <i className='fa-solid fa-home' />
                    </span>
                    <span className="menu-title">{ Website.translations.home }</span>
                  </a>
                </div>

                { appProps.canEditMerchantAccount &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.editMerchantAccountPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-user-tie' />
                      </span>
                      <span className="menu-title">{ Website.translations.editAccount }</span>
                    </a>
                  </div>
                }

                { appProps.canManageAdministrators &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.administratorsPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-user-tie' />
                      </span>
                      <span className="menu-title">{ Website.translations.administrators }</span>
                    </a>
                  </div>
                }

                { appProps.merchantsPath && appProps.canManageMerchants &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.merchantsPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-user' />
                      </span>
                      <span className="menu-title">Merchants</span>
                    </a>
                  </div>
                }

                { appProps.canManageTerminals &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.terminalsPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-mobile-screen' />
                      </span>
                      <span className="menu-title">{ Website.translations.terminals }</span>
                    </a>
                  </div>
                }

                { (appProps.canManageOrders || appProps.canReadOrders) &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.ordersPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-hand-holding-box' />
                      </span>
                      <span className="menu-title">{ Website.translations.orders }</span>
                    </a>
                  </div>
                }

                { appProps.canReadReceipts &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.receiptsPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-receipt' />
                      </span>
                      <span className="menu-title">{ Website.translations.receipts }</span>
                    </a>
                  </div>
                }

                { (appProps.canReadValidations || appProps.canManageValidations) &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.ticketsValidationPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-qrcode' />
                      </span>
                      <span className="menu-title">{ Website.translations.validations }</span>
                    </a>
                  </div>
                }

                { (appProps.canReadPromoContents || appProps.canManagePromoContents) &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.promoContentsPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-megaphone' />
                      </span>
                      <span className="menu-title">{ Website.translations.promoContent }</span>
                    </a>
                  </div>
                }

                { appProps.merchantsPath && appProps.canManageSettings &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.directorSettingsPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-gear' />
                      </span>
                      <span className="menu-title">{ Website.translations.directorSettings }</span>
                    </a>
                  </div>
                }

                { appProps.isDev &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.spacesPath }>
                      <span className="menu-icon">
                        <i className='fa-solid fa-building' />
                      </span>
                      <span className="menu-title">{ Website.translations.spaces }</span>
                    </a>
                  </div>
                }

                { appProps.sidekiqPath &&
                  <div className="menu-item menu-accordion">
                    <a className="menu-link" href={ appProps.sidekiqPath } target='_blank'>
                      <span className="menu-icon">
                        <i className='fa-solid fa-rotate' />
                      </span>
                      <span className="menu-title">{ Website.translations.sidekiq }</span>
                    </a>
                  </div>
                }

                <div className="menu-item menu-accordion">
                  <a className="menu-link" href="" onClick={ e => { e.preventDefault(); setShowLogoutAlert(true) } }>
                    <span className="menu-icon">
                      <i className='fa-solid fa-right-from-bracket' />
                    </span>
                    <span className="menu-title">{ Website.translations.logout }</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      { showLogoutAlert &&
        <ModalAlert
          alert={ Website.translations.logoutConfirmation }
          acceptText={ Website.translations.logout }
          onClose={ () => setShowLogoutAlert(false) }
          onSelect={ handleLogout }
        />
      }
    </>
  )
}
