import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'
import Modal from '../shared/Modal'
import FormInput from '../shared/FormInput'
import MerchantSubaccountCreateForm from '../merchant_subaccounts/MerchantSubaccountCreateForm'
import SubmitButton from '../shared/SubmitButton'

export default function MerchantAccounts({
  appProps, accounts, initialTotalPages, itemsPath, deletePath, disableDelete,
  canCreate, canEdit, selectedColumns, subaccountKeyDetails, initialTotalCount
}) {
  const [showModal, setShowModal] = useState(false)
  const [title, setTitle] = useState('')
  const [email, setEmail] = useState('')
  const [subaccountFields, setSubaccountFields] = useState({
    mid: '',
    email: '',
    internalName: ''
  })
  const [subaccountSettings, setSubaccountSettings] = useState(subaccountKeyDetails.map(item => ({ key: item.key, value: item.input_type === 'select' ? Object.keys(item.input_options)[0] : ''})))

  const { flashMessage } = appProps

  const actions = [
    { url: item => `${itemsPath}/${item.apiUid}/mids`, icon: 'fa-eye' }
  ]

  if (canEdit) {
    actions.push({ url: item => `${itemsPath}/${item.apiUid}/edit`, icon: 'fa-pencil' })
  }

  const handleSubaccountFieldChange = (key, e) => {
    setSubaccountFields(prevState => ({
      ...prevState,
      [key]: e.target.value
    }))
  }

  const handleSubaccountSettingChange = (key, e) => {
    setSubaccountSettings(prevState => {
      const index = subaccountSettings.findIndex(setting => setting.key === key)
      return [
        ...prevState.slice(0, index),
        { ...prevState[index], value: e.target.value },
        ...prevState.slice(index + 1)
      ]
    })
  }

  const handleSubmit = _e => {
    if (title === '' || email === '' || subaccountFields.email === '' || subaccountFields.internalName === '' || subaccountFields.mid === '') {
      flashMessage.show(Website.translations.fieldsShouldNotBeEmpty, 'error')
      return
    }
    if (email === subaccountFields.email) {
      flashMessage.show(Website.translations.sameEmailErrorMsg, 'error')
      return
    }

    const fd = new FormData()
    fd.append('merchant_account[title]', title)
    fd.append('superadmin_email', email)
    fd.append('merchant_account[subaccounts_attributes][0][internal_name]', subaccountFields.internalName)
    fd.append('merchant_account[subaccounts_attributes][0][mid]', subaccountFields.mid)
    fd.append('subaccount_superadmin_email', subaccountFields.email)
    subaccountSettings.forEach((setting, index) => {
      fd.append(`merchant_account[subaccounts_attributes][0][settings_attributes][${ index }][key]`, setting.key)
      fd.append(`merchant_account[subaccounts_attributes][0][settings_attributes][${ index }][value]`, setting.value)
    })

    Rails.ajax({
      type: 'POST',
      url: itemsPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        }
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderAccount(account, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        return (
          <div key={ column.key } className='item flex-1'>
            <div>
              { account[key] || '-' }
            </div>
          </div>
        )
      })}
    </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="xlarge"
        >
          <div className='card'>
            <div className='card-header border-0'>
              <div className='card-title m-0'>
                { Website.translations.accountData }
              </div>
            </div>
            <div className='card-body'>
              <FormInput
                label={ Website.translations.columns.title }
                value={ title }
                onChange={ e => setTitle(e.target.value) }
              />
              <FormInput
                label={ Website.translations.superadminEmail }
                value={ email }
                onChange={ e => setEmail(e.target.value) }
              />
            </div>
          </div>

          <div className='card'>
            <div className='card-header border-0'>
              <div className='card-title m-0'>
                { Website.translations.subaccountData }
              </div>
            </div>
            <div className='card-body'>
              <MerchantSubaccountCreateForm
                subaccountFields={ subaccountFields }
                handleFieldChange={ handleSubaccountFieldChange }
                keySubaccountDetails={ subaccountKeyDetails }
                subaccountSettings={ subaccountSettings }
                handleSettingChange={ handleSubaccountSettingChange }
              />
            </div>
          </div>


          <div className='flex-box content-end m-t-30'>
            <SubmitButton
              value={ Website.translations.save }
              className="btn btn-primary"
              onSubmit={ handleSubmit }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.merchantAccounts }
        breadcrumbs={ appProps.breadcrumbs }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ accounts }
        initialTotalPages={ initialTotalPages }
        selectAll={ true }
        disableCustomization={ true }
        disableDelete={ disableDelete }
        entity={ "merchant_accounts" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderAccount }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noMerchantAccounts }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        initialTotalCount={ initialTotalCount }
      />
    </>
  )
}
