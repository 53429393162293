import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import Modal from '../shared/Modal'
import ModalAlert from '../shared/ModalAlert'
import FormInput from '../shared/FormInput'
import CreateButton from '../shared/CreateButton'
import SubmitButton from '../shared/SubmitButton'

export default function Administrators({ 
  appProps, initialAdministrators, invitableRoles, invitationPath, deletePath, reinvitePath, 
  itemsPath, initialTotalPages, canCreate, canEdit, canDelete, selectedColumns, accountType, 
  merchantAccountApiUid, subaccountMid, breadcrumbs, initialTotalCount
}) {
  const [showModal, setShowModal]                   = useState(false)
  const [email, setEmail]                           = useState('')
  const [role, setRole]                             = useState(invitableRoles[0])
  const [adminToBeDeleted, setAdminToBeDeleted]     = useState(null)
  const [adminToBeReinvited, setAdminToBeReinvited] = useState(null)
  const [administrators, setAdministrators]         = useState(initialAdministrators)
  const [administratorsCount, setAdministratorsCount] = useState(initialTotalCount)

  const { flashMessage } = appProps

  const actions = [
    {
      hidden: item => !item.invitationPending,
      onClick: (e, admin) => { e.preventDefault(); setAdminToBeReinvited(admin) },
      icon: 'fa-envelope'
    }
  ]

  if (canEdit) {
    actions.push({
      url: item => `${itemsPath}/${item.apiUid}/edit`,
      hidden: item => item.role.includes('superadmin') && !appProps.merchantsPath,
      icon: 'fa-pencil'
    })
  }

  if (canDelete) {
    actions.push({
      hidden: item => item.role.includes('superadmin') && !appProps.merchantsPath,
      onClick: (e, admin) => { e.preventDefault(); setAdminToBeDeleted(admin) },
      icon: 'fa-trash'
    })
  }


  const handleSubmit = e => {
    const fd = new FormData()

    fd.append('administrator[role_id]', role.id)
    fd.append('administrator[email]', email)
    fd.append('account_type', accountType)
    if (subaccountMid) {
      fd.append('mid', subaccountMid)
    }
    if (merchantAccountApiUid) {
      fd.append('merchant_account_api_uid', merchantAccountApiUid)
    }

    Rails.ajax({
      type: 'POST',
      url: invitationPath,
      dataType: 'json',
      data: fd,
      success: _res => {
        flashMessage.show(Website.translations.administratorCreationSuccess, 'success')
        setShowModal(false)
        setTimeout(() => location.reload(), 3000)
      },
      error: _res => {
        setShowModal(false)
      }
    })
  }

  const handleDelete = id => {
    setAdminToBeDeleted(null)

    const fd = new FormData()
    fd.append('id', id)

    Rails.ajax({
      type: 'DELETE',
      url: deletePath,
      data: fd,
      dataType: 'json',
      success: res => {
        setAdministrators(prevState => {
          const index = prevState.findIndex(admin => admin.id === +res.administrator_id)

          return [
            ...prevState.slice(0, index),
            ...prevState.slice(index + 1)
          ]
        })

        flashMessage.show(res.success, 'success')
      }
    })
  }

  const handleReinvite = apiUid => {
    setAdminToBeReinvited(null)

    const fd = new FormData()
    fd.append('api_uid', apiUid)

    Rails.ajax({
      type: 'POST',
      url: reinvitePath,
      data: fd,
      dataType: 'json',
      success: res => {
        flashMessage.show(res.success, 'success')
      },
      error: res => {
        flashMessage.show(res.error, 'error')
      }
    })
  }

  function renderAdministrator(administrator, columns) {
    return (
      <>
      { columns.map( column => {
        const key = column.key
        const output = key === 'role' ? administrator[key].replace('_', ' ') : administrator[key]
        return (
          <div key={ key } className='item flex-1'>
            <div>
              { output || '-' }
            </div>
          </div>
        )
      })}
    </>
    )
  }

  return (
    <>
      { showModal &&
        <Modal
          visible={ showModal }
          closeModal={() => setShowModal(false)}
          mode="medium"
        >
          <FormInput
            label={ Website.translations.columns.email }
            value={ email }
            onChange={ e => setEmail(e.target.value) }
          />
          <FormInput
            label={ Website.translations.columns.role }
            value={ role.id }
            onChange={ e => setRole(invitableRoles.find(role => role.id === e.target.value)) }
            type='select'
            multiple={ false }
            selectWithDefault={ false }
            selectEntities={ invitableRoles.map(role => ({ ...role, key: role.key.replace('_', ' ')}) ) }
            keyAttr='id'
            valueAttr='id'
            outputAttr='key'
          />
          <div className='flex-box content-end m-t-30'>
            <SubmitButton
              value={ Website.translations.create }
              className='btn btn-primary'
              onSubmit={ handleSubmit }
            />
          </div>
        </Modal>
      }

      <CreateButton
        pageTitle={ Website.translations.administrators }
        breadcrumbs={ breadcrumbs }
        count={ administratorsCount }
        canCreate={ canCreate }
        setShowModal={ setShowModal }
      />
    
      <DynamicEntityIndex
        initialItems={ administrators }
        initialTotalPages={ initialTotalPages }
        disableCustomization={ true }
        disableDelete={ true }
        entity={ "administrators" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderAdministrator }
        itemsPath={ itemsPath }
        deletePath={ deletePath }
        noResourcesTitle={ Website.translations.noAdministrators }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        initialTotalCount={ initialTotalCount }
        updateTotalCount={ setAdministratorsCount }
      />

      { adminToBeDeleted &&
        <ModalAlert
          alert={ Website.translations.deleteAlert }
          onSelect={ () => handleDelete(adminToBeDeleted.id) }
          onClose={ () => setAdminToBeDeleted(null) }
        />
      }

      { adminToBeReinvited &&
        <ModalAlert
          alert={ Website.translations.reinviteAlert }
          onSelect={ () => handleReinvite(adminToBeReinvited.apiUid) }
          onClose={ () => setAdminToBeReinvited(null) }
        />
      }
    </>
  )
}
